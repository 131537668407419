@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  color: $primary;

  .mobile-menu-open & {
    color: white;
  }

  .menu-toggle {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    justify-content: center;

    > span {
      margin-right: 10px;
    }

    .open {
      position: relative;
      height: 26px;
      width: 36px;
      display: block;

      .mobile-menu-open & {
        display: none;
      }

      span {
        width: 100%;
        height: 3px;
        background-color: $primary;
        position: absolute;
        left: 0;
        top: 11px;

        &:first-child {
          top: 0;
        }

        &:last-child {
          bottom: 0;
          top: auto;
        }
      }
    }

    .close {
      position: relative;

      &::before,
      &::after {
        content: '';
      }
    }
  }

  #react-burger-menu-btn {
    &:focus {
      outline: none;
    }
  }
}

.menu-toggle {
  &__close {
    top: 50px;
    right: 40px;
    position: absolute;
    width: 28px;
    height: 28px;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 2px;
      height: 30px;
      background-color: white;
      transform: rotate(45deg);
      position: absolute;
      right: 50%;
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: calc(100vh) !important;
  top: 0;
  right: 0;

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    z-index: 2;
    background-color: $primary;
    transition: $transition-base;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    box-shadow: 0 4px 8px transparentize($black, 0.2);
    z-index: 1;
    background-color: $primary;
    transition: $transition-base;
  }
}

/* General sidebar styles */
.bm-menu {
  background: $primary;
  transition: $transition-base;
  padding: 100px 40px 45px 15px;
  z-index: 0;
  position: relative;
}

/* Morph shape necessary with bubble or elastic */
//.bm-morph-shape {
//  fill: #373a47;
//}

/* Wrapper for item list */
.bm-item-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
  min-height: 100%;
  overflow: auto;
  height: auto !important;
}

/* Individual item */
.bm-item {
  color: white;
  display: block;
  border-bottom: 1px solid transparentize($primary, 0.8);
  //padding: 0.5em 1em;

  &:focus {
    outline: none;
  }
}

/* Styling of overlay */
.bm-overlay {
  display: none;
}

.mobile_menu__bottom {
  display: flex;
  flex-direction: column;
  border: none;

  .search-form {
    position: relative;
    padding: 15px;

    .search-field {
      border-radius: 0;
      padding: 12px 45px 12px 15px;
      width: 100%;
      color: $primary;

      &:focus {
        box-shadow: none;
      }

      @include placeholder {
        color: $primary;
      }
    }

    .search-submit {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      padding: 5px 10px;
      border: none;
      font-size: 0;

      svg {
        path {
          fill: $primary;
        }
      }

      &:active {
        background-color: transparent !important;
        box-shadow: none !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .bottom_part {
    display: flex;
    padding: 70px 0 0;
    width: 100%;

    > nav {
      width: 100%;

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;

          &.atp-shop-item {
            width: 100%;

            a {
              display: inline-block;
              padding: 13px;
              font-size: 25px;
              color: white;

              &:hover {
                text-decoration: none;
                background-color: $orange;
                opacity: 1;
              }
            }
          }

          a {
            color: white;
            font-size: 16.5px;
            display: flex;
            align-items: center;
            padding: 0;
            text-decoration: none;

            svg {
              margin-right: 10px;
              max-height: 12px;

              path {
                fill: currentColor;
              }
            }
          }
        }
      }
    }

    .nav-footer {
      ul {
        li {
          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
