@import './../../../styles/common/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import './burger-menu-styles';

.mobile-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  padding: 0 40px;
  z-index: 5;

  &__brand {
    width: 122px;
    height: 100%;
    position: relative;
  }

  &__burger {
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
  }

  &__left {
    position: absolute;
    left: 12px;
  }
}

.rpm-mp-pusher {
  .rpm-mp-menu {
    position: relative;

    .rpm-mp-level {
      background: $primary;

      .rpm-node-exp {
        padding: 0 0.5rem;
      }
    }
  }
}

/* Dropdown Menu */

.drilldown {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  overflow: hidden;
  transition: height 500ms ease;

  .menu {
    width: 100%;
    height: 100%;
    list-style: none;
    padding: 0;
  }

  .menu-item {
    position: relative;
    display: flex;
    align-items: center;
    transition: background 500ms;
    padding: 0;
    color: $white;
    border-bottom: 1px solid $primary;

    a {
      flex-grow: 1;
      color: inherit;
      padding: 23px 0;
      font-size: 29px;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: -0.5px;
      font-weight: 700;
      font-family: $font-family-secondary;
      border-bottom: 1px solid $gray-medium;

      @include media-breakpoint-down(sm) {
        font-size: 26px;
        padding: 20px 0;
      }

      p {
        margin: 15px 0;
      }

      &:hover {
        text-decoration: none;
      }

      .parent_page {
        span {
          border-right: 1px solid $primary;
          padding: 0.5rem 10px 0.5rem 0;
          margin-right: 10px;

          svg {
            transform: scaleX(-1);

            path {
              fill: $white;
            }
          }
        }
      }
    }

    .back-button,
    .icon-right {
      padding: 0.5rem 0;
      cursor: pointer;
    }

    .back-button {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }

    .icon-right {
      padding-left: 20px;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  .menu-item:hover {
    background-color: $primary;
  }
}

/* CSSTransition classes  */

.menu-list-item {
  position: absolute;
  transform: translateX(100%);

  &-enter {
    &:first-child {
      transform: translateX(-50%);
    }

    &:last-child {
      transform: translateX(100%);
    }

    &-active {
      transition: all 500ms ease;

      &:first-child,
      &:last-child {
        transform: translateX(0%);
      }
    }
  }

  &-exit {
    position: absolute;
    top: 0;

    &:first-child {
      transform: translateX(-50%);
      transition: all 500ms ease-out;
    }

    &:last-child {
      transform: translateX(100%);
      transition: all 500ms ease-out;
      opacity: 1;
    }
  }
}
